@media only screen and (max-width: 600px) {

    :root {
        --app-width: calc(100vw - 40px);
        --app-padding: 20px 20px;
      }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.5em;
    }

    h3 {
        font-size: 1em;
    }

    .reveal{
        transform: translateY(0px);
        opacity: 1;
    }
      
    .nav-wrapper {
        align-items: center;
        height: 50px;
        padding: 10px 20px;
    }

    .intro-wrapper {
        height: calc(var(--app-height) - 40px);
        padding: var(--app-padding);
    }

    .mission-statement > h1 {
        animation: fadeInAnimation ease 2s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        width: 90%;
    }
    
    .mission-statement > h2 {
        width: 100%;
    }

    .bios-wrapper {
        background-position: 83%;
        display: flex;
        align-items: start;
        flex-direction: column;
        height: calc(var(--app-height) - 40px);
        justify-content: space-between;
        overflow: scroll;
        padding: var(--app-padding);
        text-align: left;
        width: var(--app-width);
    }

    .bios {
        padding-top: 300px;
        width: 80%
    }

    .bios > h2 {
        margin-bottom: 0px;
    }
      
    .bttn-large {
        padding: 0px 2px;
        width: 75px;
    }

    #Layer_2 {
        height: 40px;
    }

    .about-wrapper {
        align-items: flex-start;
        background: linear-gradient(0deg, rgba(141,14,254,1) 0%, rgba(55,55,207,1) 50%, rgba(141,14,254,1) 100%);
        display: flex;
        flex-direction: column;
        height: auto;
        justify-content: space-around;
        padding: var(--app-padding);
        text-align: left;
        scroll-snap-type: y mandatory;
        scroll-snap-points-y: repeat(100vh);
        width: var(--app-width);
      }

    .about-card-container {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
      }
      
      .about-card {
        background: linear-gradient(150deg, rgba(112,215,235,1)0%, rgba(112,215,235,0) 100%);
        border-radius: 20px;
        border: 2px solid var(--ice-blue);
        padding: 20px;
        width: auto;
      }
      
      .logo {
        width: 100px ;
        height: auto;
      }
      
      .logo-wrapper {
        display: flex;
        flex-direction: column;
      }
      
      .logo-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        width: var(--app-width);
        column-gap: 50px;
        row-gap: 20px;
      }

    .projects-wrapper {
        height: calc(var(--app-height) - 60px);
        padding: 40px 20px 20px 20px;
        width: var(--app-width);
      }
    
    .project-card {
        flex-direction: column;
        padding: 0px;
    }

    .project-information {
        row-gap: 20px;
    }

    .project-section {
        width: 100%;
    }

    .project-section-large {
        width: 100%;
    }

    .gallery > img {
        width: 100%;
    }

    .project-description {
        padding: 0px;
        height: 100%;
    }
}