@font-face {
  font-family: "Reclaim";   /*Can be any text*/
  src: local("Reclaim"),
    url("./fonts/Reclaim.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.App {
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  height: var(--app-height);
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --bright-red: #EA0001;
  --ice-blue: #70D7EB;
  --sand: #FAF3E3;
  --app-height: 100%;
  --app-width: calc(100vw - 120px);
  --app-padding: 20px 60px;
}

@keyframes fadeInAnimation {
  0% {
      opacity: 0;
  }
  100% {
      opacity: .7;
   }
}

.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 1;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}

h1 {
  color: white;
  font-family: 'Helvetica';
  font-size: 3em;
  opacity: 0%;
}

h2 {
  color: white;
  font-family: 'Helvetica';
  font-size: 2em;
}

h3 {
  color: white;
  font-family: 'Helvetica';
  font-size: 1.5em;
}

p {
  color: white;
  font-family: 'Helvetica';
}

.cls-1 {
  fill: #fff;
  stroke-width: 0px;
}

.intro-wrapper {
  align-items: flex-end;
  background-size: cover;
  display: flex;
  height: calc(var(--app-height) - 120px);
  padding: 20px 60px 100px 60px;
  text-align: left;
  width: var(--app-width);
  scroll-snap-align: start;
}

.mission-statement > h1 {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  width: 70%;
}

.mission-statement > h2 {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  opacity: 0%;
  width: 70%;
}

.nav-wrapper {
  display: flex;
  justify-content: space-between;
  height: 75px;
  padding: var(--app-padding);
  position: fixed;
  width: var(--app-width);
  backdrop-filter: blur(14.2px);
  -webkit-backdrop-filter: blur(14.2px);
  background: rgba(255, 255, 255, 0.24);
  z-index: 10;
}

.button-wrapper > h3 {
  margin: 0;
  padding: 0;
}

.button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  column-gap: 20px;
}

.bttn-large {
  border: 2px solid white;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  padding: 10px 10px;
  transition-duration: 300ms;
}

.bttn-large:hover {
  border: 2px solid var(--bright-red);
  background-color: var(--bright-red);
}

a {
  text-decoration: none;
}

.bttn-large > a > h3 {
  margin: 0;
  padding: 0;
}

.bios-wrapper {
  align-items: flex-end;
  background-size: cover;
  background-position: center;
  display: flex;
  position: relative;
  height: calc(var(--app-height) - 40px);
  justify-content: space-between;
  padding: var(--app-padding);
  text-align: left;
  width: var(--app-width);
  position: relative;
  scroll-snap-align: start;
}

.bios {
  display: flex;
  flex-direction: column;
  flex-direction: flex-start;
  padding-top: 0px;
  width: 50%;
}

.cv-wrapper {
  text-align: right;
}

.cv-wrapper > h3 > a {
  color: white;
  text-decoration: none;
  transition-duration: 300ms;
}

.cv-wrapper > h3 > a:hover {
  color: var(--bright-red);
  text-decoration: none;
}

.about-wrapper {
  align-items: flex-start;
  background: linear-gradient(0deg, rgba(141,14,254,1) 0%, rgba(55,55,207,1) 50%, rgba(141,14,254,1) 100%);
  display: flex;
  flex-direction: column;
  height: calc(var(--app-height) - 140px);
  justify-content: space-between;
  padding: 120px 60px 20px 60px;
  text-align: left;
  width: var(--app-width);
  position: relative;
  scroll-snap-align: start;
}

.about-card-container {
  display: flex;
  justify-content: space-around;
}

.about-card {
  background: linear-gradient(150deg, rgba(112,215,235,1)0%, rgba(112,215,235,0) 100%);
  border-radius: 20px;
  border: 2px solid var(--ice-blue);
  padding: 20px;
  width: 30%;
}

.logo {
  width: 150px ;
  height: auto;
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
}

.logo-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: var(--app-width);
  column-gap: 50px;
}

.projects-wrapper {
  align-items: flex-start;
  background: #291E30;
  display: flex;
  flex-direction: column;
  height: calc(var(--app-height) - 140px);
  justify-content: space-between;
  padding: 120px 60px 20px 60px;
  text-align: left;
  width: var(--app-width);
  position: relative;
  scroll-snap-align: start;
}

.project-card-container {
  display: flex;
  overflow: scroll;
  width: var(--app-width);
  height: 100%;
  
}

.project-card {
  display: flex;
  border-radius: 20px;
  width: 100%;
  height: auto;
  padding: 20px 40px;
  position: relative;
}

.project-title-card > h1 {
  color: white;
  font-family: 'Helvetica';
  font-size: 3em;
  opacity: 100%;
  position: relative;
  z-index: 10;
  margin-bottom: 0px;
}

.project-title-card > h4 {
  color: white;
  font-family: 'Helvetica';
  font-size: 1em;
  opacity: 100%;
  position: relative;
  z-index: 10;
  margin: 0px;
}

.collaborators-container > h3 {
  margin-bottom: 0px;
}

.alt-heading1 > h1 {
  font-family: 'Reclaim';
  font-size: 10em;
  color: #3737CF;
  transform: rotate(-5deg);
  z-index: 1;
  opacity: 75%;
}

.alt-heading1 {
  position: absolute;
  top: 10%;
  left: -5%;
}

.alt-heading2 > h1 {
  color: #633CB2;
  font-size: 10em;
  font-family: "Beth Ellen", cursive;
  font-weight: 400;
  font-style: normal;  
  opacity: 100%;
  position: absolute;
  transform: rotate(25deg);
}

.alt-heading2 {
  position: absolute;
  top: 30%;
  left: 7%;
  opacity: 75%;
  width: 100%;
}

.project-section {
  height: 100%;
  width: 33%;
}

.project-section-large {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 66%;
}

.project-information {
  display: flex;
  flex-direction: column;
  row-gap: 50px;
}

.collaborator-wrapper {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  margin-bottom: 10px;
}

.credit {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 45%;
}

.collaborator {
  font-style: italic;
  margin-top: 0px;
  margin-bottom: 0px;
  width: 45%;
}

.gallery {
  display: flex;
  align-items: center;
  height: 66%;
}

.gallery > img {
  border-radius: 10px;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.project-description {
  padding: 20px;
  height: 33%;
  max-width: 100%;
}

.projects-overflow {
  scroll-snap-align: start;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  scroll-snap-type: x mandatory;
  width: 100vW;
}

.contact-wrapper {
  align-items: start;
  background-size: cover;
  background-color: var(--bright-red);
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(var(--app-height) - 40px);
  padding: var(--app-padding);
  text-align: left;
  width: var(--app-width);
  position: relative;
  scroll-snap-align: start;
  justify-content: center;
}

.contact-form {
  width: 100%;
}

form {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
  width: auto;
}

.contact-info {
  display: flex;
  column-gap: 40px;
}

input {
  background-color: var(--bright-red);
  border: none;
  border-radius: 10px;
  color: var(--sand);
  outline: none;
  padding: 15px 10px;
  width: 200px;
}

input[type=text] {
  font-family: 'Helvetica', 'sans-serif';
  font-size: 1.5em;
  color: var(--sand);
  outline: none;
}

input::placeholder {
  font-family: 'Helvetica', 'sans-serif';
  font-size: 1em;
  color: var(--sand);
  outline: none;
}

textarea {
  background-color: transparent;
  background-color: var(--bright-red);
  border: none;
  border-radius: 10px;
  color: var(--sand);
  outline: none;
  padding: 10px 10px;
  width: auto;
  height: 30vH;
}

textarea[type=text] {
  font-family: 'Helvetica', 'sans-serif';
  font-size: 1.5em;
  color: var(--sand);
  outline: none;
}

textarea::placeholder {
  font-family: 'Helvetica', 'sans-serif';
  color: var(--sand);
  outline: none;
}

.contact-glassine {
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(14.2px);
  -webkit-backdrop-filter: blur(14.2px);
  background: rgba(255, 255, 255, 0.24);
  padding: 20px;
  border-radius: 20px;
  border: 2px solid white;
}

.contact-glassine > h1 {
  color: var(--bright-red);
  opacity: 100%;
}

button {
  font-family: 'Helvetica', 'sans-serif';
  background-color: #EA0001;
  font-size: 1em;
  color: var(--sand);
  outline: none;
}

.submit-bttn {
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  padding: 10px 10px;
  transition-duration: 300ms;
}

.submit-bttn:hover {
  border: 2px solid white;
  background-color: white;
  color: #EA0001;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 30px;
  padding: 10px 10px;
  transition-duration: 300ms;
}